'use client'

import type { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList'
import Checkbox from '@components/checkbox'
import { useEffect, useRef } from 'react'

export interface SearchRefinementListItemProps extends RefinementListItem {
  onChange: (
    value: RefinementListItem['value'],
    hasChildRefined: boolean,
    data?: SearchRefinementListItemProps[]
  ) => void
  data?: SearchRefinementListItemProps[]
}

const SearchRefinementListItem = (props: SearchRefinementListItemProps) => {
  const { value, label, data, isRefined, onChange } = props
  const hasChildRefined =
    (data && data.length > 0 && data.some(({ isRefined }) => isRefined)) ?? false
  const isExpanded = (isRefined && data && data.length > 1) || hasChildRefined
  const checkboxRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (checkboxRef?.current) checkboxRef.current.indeterminate = hasChildRefined
  }, [checkboxRef, hasChildRefined])

  return (
    <>
      <div className="flex flex-col pt-2">
        <Checkbox
          ref={checkboxRef}
          label={label}
          name={label}
          checked={isRefined}
          onChange={() => onChange(value, hasChildRefined, data)}
        />
      </div>
      {/* Here we hide the any nested items if there is only one because this is a disjunctive
        facet (or logic) and it would not matter since our hierarchical facets contain an array
        of each level, for example:
        [
          'a',
          'a > b',
          'a > c',
          'd',
          'd > e',
        ]
    */}
      {isExpanded && (
        <div className="ml-8">
          {data?.map((item) => (
            <SearchRefinementListItem key={item.value} {...item} onChange={onChange} />
          ))}
        </div>
      )}
    </>
  )
}
export default SearchRefinementListItem
