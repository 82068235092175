import { useState, useEffect } from 'react'

import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config'

const { theme } = resolveConfig(tailwindConfig)

/**
 * Returns true if the screen width is at the specified breakpoint or larger.
 */
const useScreenMediaQuery = (query: keyof typeof theme.screens) => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(`(min-width: ${theme.screens[query]})`)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => setMatches(media.matches)
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [matches, query])

  return matches
}

export default useScreenMediaQuery
